#wrapper {
    height: 90%;
    overflow: scroll;
}
table {
    border-collapse: collapse;
    width: 100%;
}
th {
    background-color: gray;
    padding: .2em .5em;
    text-align: left;
}
tr:nth-child(even) {
    background-color: aquamarine;
}
tr:nth-child(odd) {
    background-color: bisque;
}
td {
    padding: .2em .3em;
}