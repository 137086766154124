
.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#toast{
    position: fixed;
    top: 15em;
    width: 90%;
    border: 1px solid navy;
    display: none;
    left: 5%;
    padding: .3em;
}
#toast.hidden {
    display: none;
}
#toast.success {
    display: block;
    background-color: lightgreen;
}
#toast.error {
    display: block;
    background-color: pink;
}
#toast p{
    margin: 0;
}