form {
    margin: 0.3em;
    height: 100%;
}
label,
legend {
    display: block;
    margin: 0.5vh;
    font-weight: bold;
}
fieldset {
    /*    border: none; */
    padding: 1vh 0.3vh;
}
input[type="text"],
input[type="date"],
input[type="time"],
input[type="button"],
textarea,
select {
    margin-top: 0.5vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: large;
}
input[type="range"] {
    width: 95%;
    margin-right: 0.3em;
}
input[type="date"] {
    margin-right: 0.1em;
}
textarea {
    min-height: 5em;
    max-height: 45%;
    width: 95%;
    height: 100%;
}
input[type="button"] {
    margin-top: 0.5em;
    padding: 0.2em;
}
#toast {
    position: fixed;
    top: 5em;
    width: 90%;
    border: 1px solid navy;
    display: none;
    left: 5%;
    padding: 0.3em;
}
#toast.hidden {
    display: none;
}
#toast.success {
    display: block;
    background-color: lightgreen;
}
#toast.error {
    display: block;
    background-color: pink;
}
#toast p {
    margin: 0;
}
