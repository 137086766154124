* {
    margin: 0;
}
.App {
    height: 100%;
}
html,
body,
#root {
    height: 100vh;
    box-sizing: border-box;
    background-color: lightblue;
    font-size: large;
    max-width: 600px;
    display: grid;
     grid-template-rows: 50px auto;
}
html{
    background-color: wheat ;
}
input,
textarea,
button, select {
    font-size: large;
}
.succe {
    background-color: lightgreen;
    color: darkgreen;
}
.error {
    background-color: lightpink;
    color: darkred;
}
.hidden {
    display: none;
}
option {
    padding-left: 20%;
}
.rpe {
    background-color: aqua;
}
.rpe1, .rpe2 {
    background-color: lightgreen;
    text-align: right;
}
.rpe3,.rpe4 {
    background-color: green;
    text-align: right;
}
.rpe5,.rpe6 {
    background-color: orange;
    text-align: right;
}
.rpe7, .rpe8 {
    background-color: red;
    color: bisque;
    text-align: right;
}
.rpe9, .rpe10 {
    background-color: darkred;
    color: bisque;
    text-align: right;
}
